<template>
  <b-collapse
    id="collapse-1"
    v-model="showResults"
    class="mt-2 mb-2"
  >
    <b-card class="border mb-0">
      <b-card-text class="card-text">
        Resultados en Escale
      </b-card-text>
      <b-list-group-item
        v-for="(ei, index) in data"
        :key="ei.codMod"
        tag="li"
      >
        <div class="d-flex">
          <b-avatar
            :text="avatarText(removeNumbersAtStart(ei.cenEdu))"
            :variant="`light-${resolveColorByIndex(index)}`"
          />
          <div class="ml-25 w-100">
            <b-card-text class="font-weight-bold mb-0 font-small-3">
              {{ ei.cenEdu }}
            </b-card-text>
            <small class="d-block">Código Modular: {{ ei.codMod }}</small>
            <small class="d-block">Nivel: {{ ei.nivelModalidad.valor }}</small>
            <small class="d-block">Ubicación: {{ `${ei.distrito.provincia.region.nombreRegion} / ${ei.distrito.provincia.nombreProvincia} / ${ei.distrito.nombreDistrito}` }}</small>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="outline-primary"
              class="float-right"
              @click="checkEscale(ei)"
            >
              <feather-icon icon="ChevronsRightIcon" />
            </b-button>
          </div>
        </div>
      </b-list-group-item>
      <b-collapse
        id="collapse-1-inner"
        class="mt-2"
      >
        <b-card class="border mb-0">
          Hello!
        </b-card>
      </b-collapse>
    </b-card>
  </b-collapse>
</template>

<script>
import { avatarText } from '@/@core/utils/filter'
import { resolveColorByIndex } from '@/helpers/customs'
import { removeNumbersAtStart } from '@/helpers/strings'
import { BAvatar, BCollapse, BButton, VBToggle, BCard, BCardText, BListGroupItem } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAvatar,
    BButton,
    BCard,
    BCollapse,
    BCardText,
    BListGroupItem,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    showResults: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      resolveColorByIndex,
      removeNumbersAtStart,
      avatarText,
    }
  },
  methods: {
    checkEscale(dataEI) {
      this.$emit('selected-data', dataEI)
      this.$emit('update:show-results', false)
    },
  },
}
</script>
