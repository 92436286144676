<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >
        <h6
          class="text-info"
        >
          <feather-icon icon="SearchIcon" />
          <span class="align-middle ml-25">Búsqueda en Escale</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-form-group
          label="Ingrese Filtros"
          label-for="filters"
        >
          <b-input-group class="input-group-merge">
            <v-select
              id="filterEscale"
              v-model="filterEscaleSel"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsFiltersEscale"
            />
            <b-form-input
              id="search-in-escale"
              v-model="queryFilterEscale"
              style="padding: 0.438rem 1rem;"
            />
            <b-button
              variant="info"
              @click="searchInEscale"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group>
        </b-form-group>

        <div
          v-if="isBusy"
          class="text-center text-primary my-2"
        >
          <b-spinner
            variant="primary"
            class="align-middle"
          />
          <strong class="ml-1">Cargando...</strong>
        </div>

        <escale-response
          ref="rslEscale"
          :data="dataEscale"
          :show-results.sync="showResults"
          @selected-data="selectedData"
        />

        <div
          v-if="!showResults"
        >
          <h6
            class="text-info"
          >
            <feather-icon icon="AlertCircleIcon" />
            <span class="align-middle ml-25">Datos Generales</span>
          </h6>

          <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

          <input
            type="hidden"
            name="dataEscale"
            :value="formData.dataEscale"
          >

          <input
            type="hidden"
            name="dependency"
            :value="formData.dependency"
          >

          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.name"
              type="text"
            />
          </b-form-group>

          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Código Modular"
                label-for="modularCode"
              >
                <b-form-input
                  id="modularCode"
                  v-model="formData.modularCode"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Nivel"
                label-for="levelModality"
              >
                <b-form-input
                  id="levelModality"
                  v-model="formData.levelModality"
                  type="text"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Cantidad de Tutores"
                label-for="numberTutors"
              >
                <b-form-input
                  id="numberTutors"
                  v-model="numberTutors"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Cantidad de Estudiantes"
                label-for="numberStudents"
              >
                <b-form-input
                  id="numberStudents"
                  v-model="numberStudents"
                  type="text"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h6
            class="text-info"
          >
            <feather-icon icon="MapPinIcon" />
            <span class="align-middle ml-25">Ubicación</span>
          </h6>

          <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

          <b-form-group
            label="Seleccione Departamento"
            label-for="dpto"
          >
            <v-select
              v-model="dptoSel"
              :reduce="m => m.idUbigeo"
              label="description"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dptos"
              @input="getUbigeos('prov', dptoSel.slice(0, 2))"
            />
          </b-form-group>
          <b-form-group
            label="Seleccione Provincia"
            label-for="prov"
          >
            <v-select
              v-model="provSel"
              :reduce="m => m.idUbigeo"
              label="description"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="provs"
              @input="getUbigeos('dist', provSel.slice(0, 4))"
            />
          </b-form-group>
          <b-form-group
            label="Seleccione Distrito"
            label-for="dist"
          >
            <v-select
              v-model="distSel"
              :reduce="m => m.idUbigeo"
              label="description"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dists"
            />
          </b-form-group>

          <b-form-group
            label="Dirección"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="formData.address"
            />
          </b-form-group>

          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Latitud"
                label-for="latitude"
              >
                <b-form-input
                  id="latitude"
                  v-model="formData.latitude"
                  trim
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Longitud"
                label-for="longitude"
              >
                <b-form-input
                  id="longitude"
                  v-model="formData.longitude"
                  trim
                  placeholder=""
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h6
            class="text-info"
          >
            <feather-icon icon="PhoneCallIcon" />
            <span class="align-middle ml-25">Datos de Contacto</span>
          </h6>

          <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Sitio Web"
                label-for="website"
              >
                <b-form-input
                  id="website"
                  v-model="formData.website"
                  type="url"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Correo"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="formData.email"
                  type="email"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Teléfono"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="formData.phone"
                  type="tel"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="saveForm"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow, BCol, BSidebar, BForm, BFormGroup, BFormInput, BInputGroup, BButton, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
// import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import EscaleResponse from './escale/EscaleResponse.vue'

export default {
  components: {
    EscaleResponse,
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    vSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const isBusy = ref(false)
    const showResults = ref(false)
    const optionsFiltersEscale = ref(['Nombre I.E.', 'Código Modular'])
    const filterEscale = ref({
      estado: '1',
      nombreIE: '',
      codmod: '',
      ubigeo: '',
      ugel: '',
    })
    const filterEscaleSel = ref('Nombre I.E.')
    const queryFilterEscale = ref('')
    const dataEscale = ref([])
    const formData = ref({})
    const dptoSel = ref('220000')
    const provSel = ref(null)
    const distSel = ref(null)
    const dptos = ref([])
    const provs = ref([])
    const dists = ref([])
    const numberStudents = ref(0)
    const numberTutors = ref(0)

    const configDP = ref(
      {
        enableTime: false,
        dateFormat: 'd/m/Y',
        altInput: true,
        altFormat: 'd/m/Y',
      },
    )

    const resetForm = () => {
      formData.value = {}
      queryFilterEscale.value = ''
      numberStudents.value = 0
      numberTutors.value = 0
    }

    const isReadOnly = () => {
      if (filterEscaleSel.value === 'Nombre I.E.') {
        filterEscale.value.nombreIE = queryFilterEscale.value
        filterEscale.value.codmod = ''
      }
      if (filterEscaleSel.value === 'Código Modular') {
        filterEscale.value.nombreIE = ''
        filterEscale.value.codmod = queryFilterEscale.value
      }
      return true
    }

    const searchInEscale = async () => {
      isReadOnly()
      showResults.value = false
      isBusy.value = true
      dataEscale.value = []
      await store
        .dispatch('services/ESCALE_FIND_ALL', filterEscale.value)
        .then(response => {
          if (response.items) {
            if (response.items.length > 0) {
              dataEscale.value = response.items
            } else {
              dataEscale.value.push(response.items)
            }
            showResults.value = true
          } else {
            emit('error-data', { response: { data: { message: 'No se encontraron resultados en Escale.' } } })
          }
          isBusy.value = false
        })
        .catch(error => {
          isBusy.value = false
          emit('error-data', error)
        })
    }

    const saveForm = async () => {
      if (formData.value.name == null || formData.value.name === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar nombre de la Institución Educativa' } } })
        return
      }

      if (formData.value.modularCode == null || formData.value.modularCode === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar código modular de la Institución Educativa' } } })
        return
      }

      if (dptoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar departamento' } } })
        return
      }

      if (provSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar provincia' } } })
        return
      }

      if (distSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar distrito' } } })
        return
      }

      let service = 'ppptcd/EDUCATIONAL_INSTITUTION_SAVE'
      if (props.formType === 'edit') {
        service = 'ppptcd/EDUCATIONAL_INSTITUTION_UPDATE'
      }

      const additionalData = {
        numberTutors: numberTutors.value,
        numberStudents: numberStudents.value,
      }

      formData.value.additionalData = JSON.stringify(additionalData)
      formData.value.ubigeo = distSel.value

      await store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const getUbigeos = async (type, codUbigeo) => {
      await store
        .dispatch('setting/UBIGEO_FIND_ALL', {
          get: type,
          codUbigeo,
        })
        .then(response => {
          if (type === 'dpto') {
            dptos.value = response
            provSel.value = null
            distSel.value = null
            provs.value = []
            dists.value = []
          }
          if (type === 'prov') {
            provs.value = response
            provSel.value = null
            distSel.value = null
            dists.value = []
          }
          if (type === 'dist') {
            dists.value = response
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const selectedData = async data => {
      formData.value.name = data.cenEdu
      formData.value.modularCode = data.codMod
      formData.value.levelModality = data.nivelModalidad.valor
      formData.value.dependency = data.gestionDependencia.valor
      formData.value.address = data.dirCen
      formData.value.ubigeo = data.distrito.idDistrito
      formData.value.latitude = data.nlatIE
      formData.value.longitude = data.nlongIE
      formData.value.website = data.pagweb
      formData.value.email = data.email
      formData.value.phone = data.telefono
      formData.value.director = data.director
      formData.value.dataEscale = JSON.stringify(data)

      await getUbigeos('dpto', '')
      dptoSel.value = `${formData.value.ubigeo.slice(0, 2)}0000`
      await getUbigeos('prov', formData.value.ubigeo.slice(0, 2))
      provSel.value = `${formData.value.ubigeo.slice(0, 4)}00`
      await getUbigeos('dist', formData.value.ubigeo.slice(0, 4))
      distSel.value = formData.value.ubigeo
    }

    const toggleSidebar = async val => {
      emit('update:is-toggle-sidebar', val)
      resetForm()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Institución Educativa'
        await getUbigeos('dpto', '')
        await getUbigeos('prov', dptoSel.value.slice(0, 2))
      }
      if (props.formType === 'edit') {
        formData.value = {
          idEducationalInstitution: props.dataEdit.idEducationalInstitution,
          name: props.dataEdit.name,
          modularCode: props.dataEdit.modularCode,
          levelModality: props.dataEdit.levelModality,
          dependency: props.dataEdit.dependency,
          dataEscale: props.dataEdit.dataEscale,
          address: props.dataEdit.address,
          latitude: props.dataEdit.latitude,
          longitude: props.dataEdit.longitude,
          website: props.dataEdit.website,
          email: props.dataEdit.email,
          phone: props.dataEdit.phone,
          ubigeo: props.dataEdit.ubigeo,
        }
        const additionalData = JSON.parse(props.dataEdit.additionalData)
        numberTutors.value = additionalData.numberTutors
        numberStudents.value = additionalData.numberStudents
        dptoSel.value = `${props.dataEdit.ubigeo.slice(0, 2)}0000`
        await getUbigeos('dpto', '')
        await getUbigeos('prov', props.dataEdit.ubigeo.slice(0, 2))
        provSel.value = `${props.dataEdit.ubigeo.slice(0, 4)}00`
        await getUbigeos('dist', props.dataEdit.ubigeo.slice(0, 4))
        distSel.value = props.dataEdit.ubigeo
        titleForm.value = 'Editar Institución Educativa'
      }
    }

    return {
      titleForm,
      isBusy,
      formData,
      searchInEscale,
      saveForm,
      toggleSidebar,
      resetForm,
      configDP,
      optionsFiltersEscale,
      filterEscaleSel,
      filterEscale,
      queryFilterEscale,
      dataEscale,
      dptoSel,
      provSel,
      distSel,
      dptos,
      provs,
      dists,
      getUbigeos,
      showResults,
      selectedData,
      numberStudents,
      numberTutors,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
