<template>
  <b-sidebar
    id="add-new-directory-sidebar"
    :visible="isToggleSidebarDirectory"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >
        <h6
          class="text-info"
        >
          <feather-icon icon="AlertCircleIcon" />
          <span class="align-middle ml-25">Datos Generales</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-form-group
          label="Documento de Identidad"
          label-for="nroDoc"
        >
          <b-input-group class="input-group-merge">
            <v-select
              id="frmTipoDoc"
              v-model="tipoDocSel"
              label="abreviatura"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tiposDocument"
              @input="isReadOnly()"
            />
            <b-form-input
              id="search-nro-doc"
              v-model="dataDirectorio.dni"
              style="padding: 0.438rem 1rem;"
            />
            <b-button
              variant="info"
              @click="searchTrabajador"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group>
        </b-form-group>

        <div
          v-if="isBusy"
          class="text-center text-primary my-2"
        >
          <b-spinner
            variant="primary"
            class="align-middle"
          />
          <strong class="ml-1">Cargando...</strong>
        </div>

        <b-media class="mb-0">
          <template #aside>
            <b-avatar
              ref="previewEl"
              :src="`data:image/jpeg;base64,${fotoReniec}`"
              size="132px"
              rounded
              style="width: 132px; height: 208px;"
            />
          </template>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Nombres"
                label-for="nombre"
              >
                <b-form-input
                  id="nombre"
                  v-model="dataDirectorio.firstName"
                  :readonly="isReadOnly()"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Apellido Paterno"
                label-for="apellido_paterno"
              >
                <b-form-input
                  id="apellido_paterno"
                  v-model="dataDirectorio.lastNameOne"
                  :readonly="isReadOnly()"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Apellido Materno"
                label-for="apellido_materno"
              >
                <b-form-input
                  id="apellido_materno"
                  v-model="dataDirectorio.lastNameTwo"
                  :readonly="isReadOnly()"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-media>

        <h6
          class="text-info"
        >
          <feather-icon icon="BriefcaseIcon" />
          <span class="align-middle ml-25">Datos Laborales</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-form-group
          label="Institución Educativa"
          label-for="eeii"
        >
          <b-form-input
            id="eeii"
            v-model="directorySel.name"
            :readonly="isReadOnly()"
          />
        </b-form-group>

        <b-form-group
          label="Cargo"
          label-for="position"
        >
          <v-select
            id="position"
            v-model="dataDirectorio.position"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="positions"
          />
        </b-form-group>

        <h6
          class="text-info"
        >
          <feather-icon icon="PhoneCallIcon" />
          <span class="align-middle ml-25">Datos de Contacto</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Celular"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="dataDirectorio.phone"
                type="tel"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Correo"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="dataDirectorio.email"
                type="email"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow, BCol, BAvatar, BMedia, BSidebar, BForm, BFormGroup, BFormInput, BInputGroup, BButton, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
// import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BMedia,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    vSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebarDirectory',
    event: 'update:is-toggle-sidebar-directory',
  },
  props: {
    isToggleSidebarDirectory: {
      type: Boolean,
      required: true,
    },
    directorySel: {
      type: Object,
      required: false,
      default: null,
    },
    dataEditDirectory: {
      type: Object,
      required: false,
      default: null,
    },
    formTypeDirectory: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const isBusy = ref(false)
    const tiposDocument = ref(['DNI'])
    const tipoDocSel = ref('DNI')
    const dataDirectorio = ref({})
    const fotoReniec = ref('')
    const positions = ref(['DIRECTOR', 'SUBDIRECTOR', 'COORDINADOR TUTORIA', 'TUTOR'])
    const readonlyFrm = ref(false)

    const configDP = ref(
      {
        enableTime: false,
        dateFormat: 'd/m/Y',
        altInput: true,
        altFormat: 'd/m/Y',
      },
    )

    const resetForm = () => {
      dataDirectorio.value = {}
      fotoReniec.value = ''
    }

    const searchTrabajador = async () => {
      isBusy.value = true
      await store
        .dispatch('services/RENIEC_FIND_CIUDADANO', {
          dni: dataDirectorio.value.dni,
        })
        .then(response => {
          if (response.datosPersona) {
            dataDirectorio.value.firstName = response.datosPersona.prenombres
            dataDirectorio.value.lastNameOne = response.datosPersona.apPrimer
            dataDirectorio.value.lastNameTwo = response.datosPersona.apSegundo
            dataDirectorio.value.photo = response.datosPersona.foto
            dataDirectorio.value.direccionReniec = response.datosPersona.direccion
            dataDirectorio.value.ubigeoReniec = response.datosPersona.ubigeo
            fotoReniec.value = response.datosPersona.foto
          }
          isBusy.value = false
        })
        .catch(error => {
          isBusy.value = false
          emit('error-data', error)
        })
    }

    const saveForm = async () => {
      // Validar datos antes

      let service = 'ppptcd/EDUCATIONAL_INSTITUTION_DIRECTORY_SAVE'
      if (props.formTypeDirectory === 'edit') {
        service = 'ppptcd/EDUCATIONAL_INSTITUTION_DIRECTORY_UPDATE'
      }

      dataDirectorio.value.idEducationalInstitution = props.directorySel.idEducationalInstitution

      await store.dispatch(service, dataDirectorio.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar-directory', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar-directory', false)
        })
    }

    const isReadOnly = () => true

    const toggleSidebar = async val => {
      emit('update:is-toggle-sidebar', val)
      resetForm()
      if (props.formTypeDirectory === 'new') {
        titleForm.value = 'Agregar Personal'
      }
      if (props.formTypeDirectory === 'edit') {
        dataDirectorio.value = {
          idEducationalInstitutionDirectory: props.dataEditDirectory.idEducationalInstitutionDirectory,
          idEducationalInstitution: props.dataEditDirectory.idEducationalInstitution,
          firstName: props.dataEditDirectory.firstName,
          lastNameOne: props.dataEditDirectory.lastNameOne,
          lastNameTwo: props.dataEditDirectory.lastNameTwo,
          dni: props.dataEditDirectory.dni,
          position: props.dataEditDirectory.position,
          phone: props.dataEditDirectory.phone,
          email: props.dataEditDirectory.email,
          photo: props.dataEditDirectory.photo,
          status: props.dataEditDirectory.status,
        }
        fotoReniec.value = props.dataEditDirectory.photo
        tipoDocSel.value = 'DNI'
        titleForm.value = 'Editar Personal'
      }
    }

    return {
      titleForm,
      isBusy,
      tiposDocument,
      tipoDocSel,
      dataDirectorio,
      searchTrabajador,
      fotoReniec,
      saveForm,
      toggleSidebar,
      isReadOnly,
      positions,
      resetForm,
      readonlyFrm,
      configDP,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
