/* eslint-disable arrow-body-style */
export const removeNumbersAtStart = str => {
  const newStr = str.replace(/^\d+\s*/, '')

  return newStr.trim()
}

export const clearSpaces = str => {
  const strings = str.split(',')
  const result = strings.map(stringClear => stringClear.trim())

  return result
}

export const capitalizeWords = inputString => inputString
  .toLowerCase()
  .replace(/(?:^|\s)\S/g, char => char.toUpperCase())

export const shortString = (string, lengthStr) => {
  return string.length <= lengthStr ? string : `${string.slice(0, lengthStr)}...`
}
